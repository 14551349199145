import React from 'react';
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../../utils/lang';
import logo from "../../../assets/images/logo_labeled_white@3x.png";

const Header = () => {
    const { t } = useTranslation('onboarding');
    const { changeLanguage, getLanguage } = useLanguage();

    return (
        <header className="prod_ Header relative bg-primary text-white py-xs md:py-md rounded-br-2xl md:rounded-br-4xl shadow-lg">
            <div className="!w-auto container container--nomaxwidth absolute left-0 top-1/2 -translate-y-1/2">
                <a className="inline-block" href="https://www.mygrid.ch/" target="_blank"><img className="Header__logo max-w-full w-[5.875rem] md:w-[10.5rem]" src={logo} alt="Logo"/></a>
            </div>
            <div className="container text-right">
                <div className="text-right">
                    <div className="md:pb-xs">
                        <button
                            className={'text-xs md:text-base pl-xs md:pl-sm pr-0 py-0 transition-colors hover:text-secondary ' + (getLanguage() === 'de' && 'text-secondary')}
                            onClick={() => changeLanguage('de-CH')}>DE
                        </button>
                        <button
                            className={'text-xs md:text-base pl-xs md:pl-sm pr-0 py-0 transition-colors hover:text-secondary ' + (getLanguage() === 'en' && 'text-secondary')}
                            onClick={() => changeLanguage('en-CH')}>EN
                        </button>
                        <button
                            className={'text-xs md:text-base pl-xs md:pl-sm pr-0 py-0 transition-colors hover:text-secondary ' + (getLanguage() === 'fr' && 'text-secondary')}
                            onClick={() => changeLanguage('fr-CH')}>FR
                        </button>
                    </div>
                    <div className="Header__title text-lg md:text-3xl pb-xs">{t('heading')}</div>
                </div>
            </div>
        </header>
    );
}

export default Header;